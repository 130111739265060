import { Component, inject } from '@angular/core';

import { APP_CONFIG } from '@services/app-config.token';

@Component({
  standalone: true,
  templateUrl: './admin-no-access.component.html',
  styleUrls: ['./admin-no-access.component.scss'],
})
export class AdminNoAccessComponent {
  public readonly homePageUrl = inject(APP_CONFIG).NGHomePage;
}
