import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Observable } from 'rxjs';

import { Clipboard } from '@angular/cdk/clipboard';
import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';

import { IconComponent } from '../icon/icon.component';

import { IconsService } from './icons.service';

@Component({
  standalone: true,
  imports: [AsyncPipe, TooltipModule, IconComponent],
  selector: 'app-icons-page',
  templateUrl: './icons-page.component.html',
  styleUrls: ['./icons-page.component.scss'],
  providers: [IconsService],
})
export class IconsPageComponent implements OnInit {
  private readonly svgIconService = inject(IconsService);
  private readonly clipboard = inject(Clipboard);

  searchIconNameString: string;
  iconNames$: Observable<string[]>;

  ngOnInit(): void {
    this.iconNames$ = this.svgIconService.getIconNames();
  }

  onIconClick(iconName: string): void {
    this.clipboard.copy(iconName);
    alert('Copied');
  }

  onIconSearch(iconName: string): void {
    this.searchIconNameString = iconName;
  }
}
