import { InjectionToken } from '@angular/core';

import { IAppConfig } from '../models/app-config.model';

declare global {
  interface Window {
    CONFIG_TRANSFER: IAppConfig;
  }
}
export const APP_CONFIG = new InjectionToken<IAppConfig>('CONFIG_TRANSFER', {
  providedIn: 'root',
  factory: () => window.CONFIG_TRANSFER,
});
