@if (iconNames$ | async; as iconNames) {
  <div class="icons-page">
    <div class="icons-page__header">
      <h1>Unity Icons</h1>
      <input #searchInput (input)="onIconSearch(searchInput.value)" type="search" placeholder="Search icon ..." />
    </div>
    <div class="icons-page__icons">
      @for (iconName of iconNames; track iconName) {
        <div
          class="icons-page__icon"
          [tooltip]="iconName"
          [hidden]="searchIconNameString && !iconName.includes(searchIconNameString)"
          [delay]="100"
          container="body"
          (click)="onIconClick(iconName)"
        >
          <div class="icons-page__icon__content">
            <app-icon [icon]="iconName" [color]="'#141414b3'" [size]="24"></app-icon>
          </div>
        </div>
      }
    </div>
  </div>
}
